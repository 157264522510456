<script>
export default {
    name: "relative-date-term",

    inheritAttrs: false,

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            type: String
        },

        placeholder: {
            type: String
        },

        state: {
            type: Boolean
        }
    },

    computed: {
        model: {
            get: function () {
                const pieces = this.modelValue?.replace('+', '')?.split(' ')

                return {
                    number: Number(pieces?.[0]),
                    unit: pieces?.[1]
                }
            },
            set: function (value) {
                this.$emit('update:modelValue', '+' + value.number + ' ' + value.unit)
            },
        },

        options: function () {
            return ['day', 'week', 'month', 'year'].map(str => {
                return {
                    value: str,
                    translation: this.$t('dates.' + str)
                }
            })
        },
    },

    methods: {
        change: function () {
            this.$emit('update:modelValue', '+' + this.model.number + ' ' + this.model.unit)
        },
    }
}
</script>

<template>
    <div :class="$attrs.class" class="d-flex align-items-center">
        <input
            type="number"
            :id="$attrs.id"
            v-model="model.number"
            :class="{'border-danger': state}"
            :placeholder="placeholder"
            class="form-control me-3"
            :name="$attrs.name"
            @change="change"
        />

        <div class="form-check me-3"
             v-for="(option, index) in options"
             :key="index"
        >
            <input
                class="form-check-input"
                type="radio"
                :value="option.value"
                :id="_.uid + '_' + index + '_' + option.value"
                :name="_.uid"
                v-model="model.unit"
                @change="change"
            />
            <label
                :for="_.uid + '_' + index + '_' + option.value"
                class="form-check-label font-size-16 ms-2">
                {{ option.translation }}
            </label>
        </div>
    </div>
</template>

<style scoped>
    .form-check-input {
        height: 16px;
        width: 16px;
    }
</style>
