<script>
import {defineComponent} from 'vue'
import TransitionToggle from "../transitions/toggle.vue";

export default defineComponent({
    name: "multiple-select",

    inheritAttrs: false,

    components: {TransitionToggle},

    emits: ['input', 'update', 'update:modelValue', 'change'],

    props: {
        modelValue: {},

        label: {
            type: String
        },
        placeholder: {
            type: String
        },
        list: {
            type: Array
        },
        store: {
            type: [String, Array]
        },
        valueField: {
            type: String,
            default: 'id'
        },
        textField: {
            type: String,
            default: 'name'
        },
        extraField: {
            type: String
        },
        extraFormatter: {
            type: Function
        },
        morph: {
            type: [String, Object],
            default: null
        },
        morphColumn: {
            type: String
        },
        pivotStore: {
            type: String
        },
        action: {
            type: String,
            default: 'all'
        },
        query: {
            type: Object
        }
    },

    data: function () {
        return {
            selected: this.modelValue || [],
            search: '',
            focused: false,
            timeout: null,
            pivotFormShow: false,
            pivot: {},
            edited: null
        }
    },

    computed: {
        options: function () {
            if (this.list?.length) {
                return this.list
            } else if (typeof this.store === 'string') {
                return this.$store.getters[`${this.store}/${this.action}`];
            } else if (Array.isArray(this.store)) {
                let result = [];

                this.store.forEach(st => {
                    result = result.concat(this.$store.getters[`${st}/${this.action}`].map(i => {
                        i.morph = this.morph[st]
                        return i
                    }))
                });

                return result;
            }

            return []
        },

        filtered: function () {
            if (!this.search) {
                if (typeof this.store === 'string') {
                    return this.options.filter(option => this.selected.every(s => s[this.valueField] !== option[this.valueField]));
                } else if (Array.isArray(this.store)) {
                    return this.options.filter(option => this.selected.every(s => !(s[this.valueField] === option[this.valueField] && s.morph === option.morph)));
                } else {
                    return this.options;
                }
            }

            return this.options.filter(option => {
                if (this.textField === 'name' && option.translation_key) {
                    return this.$t(option.translation_key).toLowerCase().includes(this.search.toLowerCase())
                } else if (option[this.textField]) {
                    option[this.textField].includes(this.search)
                }
                return false
            }).filter(option => !this.selected.some(s => s[this.valueField] === option[this.valueField]))
        },
    },

    methods: {
        addToSelected: function (option) {
            this.selected.push(option)
            clearTimeout(this.timeout)
            this.timeout = null
            this.open()
            if (!this.morph) {
                this.$emit('change', this.selected);
                if (this.pivotStore) {
                    this.$emit('update:modelValue', this.selected);
                } else {
                    this.$emit('update:modelValue', this.selected.map(s => s[this.valueField]));
                }
            } else {
                if (this.pivotStore) {
                    this.$emit('change', this.selected);
                    this.selected.map(s => {
                        if (typeof this.store === 'string') {
                            s[`${this.store}_type`] = this.morph;
                            s[`${this.store}_id`] = s.id
                        } else if (Array.isArray(this.store)) {
                            s[`${this.morphColumn}_type`] = s.morph
                            s[`${this.morphColumn}_id`] = s.id
                        }
                        return s;
                    })
                    this.$emit('update:modelValue', this.selected);
                } else {
                    this.$emit('change', this.selected);
                    this.$emit('update:modelValue', this.selected.map(s => {
                        const obj = {};
                        if (typeof this.store === 'string') {
                            obj[`${this.store}_type`] = this.morph;
                            obj[`${this.store}_id`] = s.id
                        } else if (Array.isArray(this.store)) {
                            obj[`${this.morphColumn}_type`] = s.morph
                            obj[`${this.morphColumn}_id`] = s.id
                        }
                        return obj;
                    }));
                }
            }
        },

        removeFromSelected: function (option) {
            const index = this.morph ? this.selected.findIndex(s => JSON.stringify(s) === JSON.stringify(option) ) : this.selected.findIndex(s => s.id === option.id);

            if (index !== -1) {
                this.$emit('change', this.selected, this.selected.splice(index, 1));
            }

            if (!this.morph) {
                this.$emit('update:modelValue', this.selected.map(s => s.id));
            } else {
                this.$emit('update:modelValue', this.selected.map(s => {
                    const obj = {};
                    obj[`${this.store}_type`] = this.morph
                    obj[`${this.store}_id`] = s.id
                    return obj
                }));
            }
        },

        open: function () {
            if (this.timeout) {
                clearTimeout(this.timeout)
                this.timeout = null
            }
            if (!this.focused) {
                this.focused = true;
            }
            this.$refs.input.focus()
        },

        close: function () {
            this.timeout = setTimeout(() => {
                this.focused = false
            }, 100)
        },

        select: function ($event, option) {
            this.pivot = option.pivot || {}
            this.edited = option
            this.pivotFormShow = true
            this.$store.dispatch(`${this.pivotStore}/setItem`, this.pivot)
            $event.stopPropagation()
        },

        pivotClose: function () {
            this.pivotFormShow = false
        },

        attachPivot: function (pivot) {
            this.edited.pivot = pivot
        },
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function (value) {
                if (value && !this.selected?.length) {
                    this.selected = value;
                }
            },
            deep: true
        },
    },

    created() {
        if (typeof this.store === 'string') {
            this.$store.dispatch(`${this.store}/${this.action}`, this.query)
        } else if (Array.isArray(this.store)) {
            this.store.forEach(st => {
                this.$store.dispatch(`${st}/${this.action}`, this.query)
            })
        }
    }
})
</script>

<template>
    <div class="mb-3" :class="$attrs.class" :key="modelValue?.length">
        <div class="form-control p-0 overflow-hidden">
            <div class="p-2" :class="{'mb-2': focused}" @click.prevent="open">
                <div v-for="(option, index) in selected" :key="index" class="choices__list choices__list--multiple" :contenteditable="false">
                    <b-badge :variant="edited?.id === option.id ? 'success' : 'primary'" class="me-2">
                        <span :class="{'px-2': !pivotStore}">
                            {{ option.translation_key && textField === 'name' ? $t(option.translation_key).ucFirst() : option[textField] }}
                            <span class="ms-2" v-if="extraField">{{ extraFormatter ? extraFormatter(option[extraField]) : option[extraField] }}</span>
                        </span>
                        <span v-if="pivotStore" class="border-left-1 border-white ms-3 px-2 cursor-pointer" @click.prevent="select($event, option)">
                            <i class="fas fa-pencil-alt font-size-8"></i>
                        </span>
                        <span class="ps-2 border-left-1 border-white d-inline-block cursor-pointer" @click="removeFromSelected(option)">x</span>
                    </b-badge>
                </div>
                <input :id="$attrs.id" ref="input" :name="$attrs.id" class="border-0" v-model="search" @blur="close"/>
            </div>
            <template v-if="pivotStore">
                <transition-toggle>
                    <slot name="pivot-form" v-if="pivotFormShow" v-bind="{close: pivotClose, attach: attachPivot}"></slot>
                </transition-toggle>
            </template>
            <transition-toggle>
                <div class="choices__list bg-light border-top-0 py-3" v-if="focused">
                    <div v-for="(option, index) in filtered" :key="index" class="choices__item cursor-pointer px-3" @click.prevent="addToSelected(option)">
                        {{ option.translation_key && textField === 'name' ? $t(option.translation_key).ucFirst() : option[textField] }}
                        <small class="ms-2" v-if="extraField">{{ extraFormatter ? extraFormatter(option[extraField]) : option[extraField] }}</small>
                    </div>
                </div>
            </transition-toggle>
        </div>
    </div>
</template>

<style scoped lang="scss">
    :focus-visible {
        outline: unset;
    }

    .choices__input.choices__input--cloned {
        border-color: transparent;
        &:focus-visible {
            outline: transparent;
        }
    }

    .border-left-1 {
        border-left-width: 1px;
        border-left-style: solid;
    }

    .choices__list:not(.choices__list--multiple) {
        max-height: 300px;
        overflow-y: scroll;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .choices__item {
            &:hover {
                background: white;
                font-weight: 500;
            }
        }
        &::-webkit-scrollbar {
            width: 2px;
            background: rgba(0,0,0,0.1);
        }
        &::-webkit-scrollbar-thumb {
            width: 2px;
            background: rgba(0,0,0,0.2);
        }
    }
</style>
